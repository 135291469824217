<template>
    <v-footer
      id="footer"
      padless>
      <v-row
        justify = "center"
        no-gutters>
        <v-card
          flat
          class = "text-center"
          color = "grey lighten-4">
          <v-card-text>
            <v-btn 
              @click="showAdress = !showAdress"
              outlined>
              
              Kontakt
            </v-btn>  
          </v-card-text>
        </v-card>
      </v-row>
    </v-footer>
</template>
<script>
export default {
  name: "Footer",
  data() {
      return {
        showAdress: false
      }
  }
}
</script>